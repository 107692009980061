import React from 'react'
import '../style/how-tracker-works.scss'
const HowTrackerWorks = (props) => {

    function changePath() {

        document.querySelector(".App").classList.add("loader");
        setTimeout(() => {
            document.querySelector(".App").classList.remove("loader");
            props.history.push({pathname: '/'});
        }, 800);
        document.querySelector(".App").classList.add("loader-leave");
    }

    return (
        <div className="container how-works">
            <div className="row">
                <div className="col-md-2">
                    <div className="btn-back" onClick={changePath}><span></span>Back to tracker</div>
                </div>
                <div className="col-md-8 text-center">
                    <h2 className="heading-2 h-underline">HOW DOES THE TRACKER WORKS</h2>
                </div>
            </div>
            <div className="row">
                <div className="desc col-md-8 offset-md-2">
                    <h3>The Commonwealth’s commitment to end malaria by 2030</h3>
                    <p>In October 2024, at the Commonwealth Heads of Government Meeting held in Samoa, leaders reaffirmed the Commonwealth’s longstanding commitment to fight malaria and end the disease across the Commonwealth by 2030.</p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>The Commonwealth malaria tracker</h3>
                    <p>The Commonwealth Malaria Tracker has been developed with the objective of using a single platform to visualize progress towards the Commonwealth commitment, and to assist member states in their process of review.</p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>How the Commonwealth commitment is measured </h3>
                    <p>The Commonwealth’s commitment aligns the targets set out in the World Health Organization's (WHO) Global Technical Strategy (GTS) for Malaria.</p>
                    <p>The GTS underpins the Sustainable Development Goals target related to malaria, already adopted by member states and using 2015 as the baseline year for measuring progress. The two indicators to measure progress towards the commitment apply both to the Commonwealth as an aggregate and to any Commonwealth country with cases or deaths from malaria.</p>
                    <p>These are:</p>
                    <ol>
                        <li>On track to reduce malaria incidence by 90% by 2030</li>
                        <li>On track to reduce malaria mortality rates by 90% by 2030</li>
                    </ol>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>How progress is reported</h3>
                    <p>Countries are “on-track”, “ or “off-track” to achieve the Commonwealth commitment. Countries are shown to be on track where they have:</p>
                    <ul>
                        <li>Reduced the malaria case incidence rate (per 1,000 of population at risk) to a level equal to, or greater than, the linear projection required to reach a 90% reduction by 2030 (against a 2015 baseline).</li>
                        <li>Reduced the malaria mortality rate (per 100,000 of population at risk) to a level equal to, or greater than, the linear projection required to reach a 90% reduction by 2030 (against a 2015 baseline).</li>
                    </ul>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>About the data:</h3>
                    <p>All country data on this site is taken directly from the 2024 World Malaria Report. Case incidence, mortality rate and country progress status are derived directly from this data set.</p>

                    <p>The WHO’s estimates are consistent across all countries in the Commonwealth and enable member states to review progress towards ending malaria without requiring any additional data reporting by individual countries. Given the significant range of countries within the Commonwealth, the use of a standardized reporting method for measuring progress was vital to delivering this tracker</p>
                    <p>Data for the Commonwealth is produced by aggregating the total for all relevant member countries in each individual year. Relevant member countries are defined as those which had any malaria cases or deaths in the WHO’s data.</p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>About the partners</h3>
                    <p>Content for this site was originally developed by a group of malaria technical partners including:</p>
                    <ul className="list-style-dash">
                        <li>The African Leaders Malaria Alliance</li>
                        <li>The Asia Pacific Leaders Malaria Alliance</li>
                        <li>Malaria No More UK,</li>
                        <li>The RBM Partnership To End Malaria</li>
                        <li>The World Health Organisation </li>
                    </ul>
                    <p>Development and design for the site have been provided by Isobar.</p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>Find out more:</h3>
                    <p className="padding-last">If you have any questions about the site, please contact <a href="mailto:infomnm@malarianomore.org.uk"> infomnm@malarianomore.org.uk </a> </p>
                </div>
            </div>
        </div>
    )
}

export default HowTrackerWorks;
